/*
Cuando se esta probando en ambiente local NO! se deben colocar / en los paths al final
 */
export const IS_URL                = process.env.REACT_APP_IS_URL       || 'https://localhost:5100';
export const API_URL               = process.env.REACT_APP_API_URL      || 'https://localhost:5000/api';
export const WEB_URL               = process.env.REACT_APP_WEB_URL      || 'https://localhost:3000';
export const AUTH_IS_URL           = process.env.REACT_APP_AUTH_IS_URL  || 'https://localhost:5100'
export const BUSQUEDAS_DB          = 'Busquedas';
export const METADATA_EMISION_DB   = 'MetadataEmision';
export const METADATA_RECEPCION_DB = 'MetadataRecepcion';
export const CONCILIACIONES_DB     = 'Conciliaciones';
export const CLIENT_ID             = process.env.REACT_APP_CLIENT_ID || 'InsightsSite'
export const CLIENT_SECRET         = process.env.REACT_APP_CLIENT_SECRET || ''
export const OAUTH                 = process.env.REACT_APP_OAUTH || 'oauth'
export const JWKS                  = process.env.REACT_APP_JWKS || 'jwks'

export const NOMINA_DATOS_GENERALES      = "NominaDatosGenerales";
export const NOMINA_GRID_DATOS_GENERALES = "NominaGridDatosGenerales";
export const NOMINA_PERCEPCIONES         = "NominaPercepciones";
export const NOMINA_DEDUCCIONES          = "NominaDeducciones";
export const NOMINA_INCAPACIDADES        = "NominaIncapacidades";
export const NOMINA_JUBILACION           = "NominaJubilacion";
export const NOMINA_INDEMNIZACION        = "NominaIndemnizacion";
export const NOMINA_OTROS_PAGOS          = "NominaOtrosPagos";
